<template>
    <div class="footer_wrapper footer">
        <div class="small_footer">
            <div class="mobile_footer md:hidden flex flex-wrap flex-row justify-start items-center">
                <p class="text-white text-left pl-4 w-full">{{ $t("believe") }}</p>
                <a href="#" class="bottom_logo">
                    <img loading="lazy" decoding="async" src="@/assets/Images/go_logo.svg" alt="" />
                </a>
                <span class="rights flex-1 grow ml-auto">{{ $t("GO Platform All rights reserved") }}</span>
            </div>
            <footer>
                <div class="footer_section">
                    <a href="/" class="bottom_logo">
                        <img loading="lazy" decoding="async" src="@/assets/Images/go_logo_white.svg" alt="" />
                    </a>
                    <p>{{ $t("believe") }}</p>
                    <span class="rights">{{ $t("GO Platform All rights reserved") }}</span>
                </div>
                <nav class="footer_section footer_links">
                    <div>
                        <a href="#"> {{ $t("Company") }}</a>
                        <a href="/#" @click="scrollTo('contact')"> {{ $t("Contact us") }}</a>
                        <a href="/Talents">{{ $t("Talents") }}</a>
                    </div>
                </nav>
                <nav class="footer_section footer_links">
                    <div>
                        <a href="#"> {{ $t("Services") }}</a>
                        <a href="/planning"> {{ $t("Pricing") }}</a>
                        <a href="/articles"> {{ $t("Blogs") }}</a>
                    </div>
                </nav>
                <nav class="footer_section footer_links">
                    <div>
                        <a href="/articles"> {{ $t("Blogs") }}</a>
                        <a href="/Terms-of-use"> {{ $t("Terms") }}</a>
                        <a href="/privacy-policy">{{ $t("Privacy policy") }}</a>
                    </div>
                </nav>
                <div class="footer_section social_links">
                    <a href="https://www.facebook.com/GO.Platform" target="_blank" class="bottom_logo">
                        <img loading="lazy" decoding="async" src="@/assets/Images/icons/white_fb_icon.svg" alt="" />
                    </a>
                    <a href="https://www.linkedin.com/company/go-platform/posts/?feedView=all" target="_blank" class="bottom_logo">
                        <img loading="lazy" decoding="async" src="@/assets/Images/icons/white_ln_icon.svg" alt="" />
                    </a>
                    <a href="https://www.instagram.com/go.platform/" target="_blank" class="bottom_logo">
                        <img loading="lazy" decoding="async" src="@/assets/Images/icons/white_instagram_icon.svg" alt="" />
                    </a>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "footerPage",
    data() {
        return {};
    },
    methods: {
        scrollTo(targetID) {
            document.querySelector(`#${targetID}`).scrollIntoView();
        },
    },
};
</script>

<style lang="scss" scoped>
.footer_wrapper {
    height: fit-content;
    background: linear-gradient(180deg, #1642f8 0%, #2196f3 92%);
    margin-top: 5%;
    overflow-x: auto;

    .footer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(9.9px);
        -webkit-backdrop-filter: blur(9.9px);
        padding-bottom: 2rem;

        .mobile_footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .bottom_logo {
                margin: 1rem 0;
            }
        }

        footer {
            width: 95%;
            margin: 2.5%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            white-space: nowrap;
            overflow-x: auto;

            .footer_section {
                flex-shrink: 0;
                width: 22%;
                margin-bottom: 1.5rem;
                padding-left: 0 !important;

                &:first-child {
                    width: 100%;

                    @media (min-width: 640px) {
                        width: 100%;
                    }

                    @media (min-width: 1024px) {
                        width: 22%;
                    }
                }

                @media (max-width: 464px) {
                    width: 100%;
                }
            }

            .footer_links {
                display: flex;
                flex-direction: column;
                color: #ffffff;
                padding-left: 0 !important;

                a {
                    color: #ffffff;
                    font-weight: 500;
                    margin: 0.5rem 0;
                    text-decoration: none;
                }

                @media (max-width: 464px) {
                    width: 100%;
                }
            }

            .social_links {
                display: flex;
                justify-content: space-around;

                a {
                    margin: 0 10px;
                }
            }
        }

        .rights {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #ffffff;
            display: flex;
            width: 95%;
            padding-left: 2.5%;
        }
    }
}
</style>
