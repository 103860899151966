<template>
    <div class="flex flex-col lg:flex-row justify-between">
        <div class="flex justify-between items-center w-full mb-[50px]">
            <h3 class="recentapp">{{ $t("Candidates") }}</h3>
            <img src="@/assets/Search-Add-Outline.png" alt="Search Add Outline Icon" class="w-5 h-5" />
        </div>
    </div>
    <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com -->
    <div class="flex p-1 flex-col mt-3 gap-3 overflow-hidden" v-if="candidates.length > 0">
        <div class="flex justify-between w-full items-center">
            <div class="w-[20%]">
                <input
                    placeholder="Filter Names"
                    class="font-light !text-sm !py-2 !px-3 !border !border-[1.5px] !rounded-md w-full !border-[rgb(228, 228, 231)] focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="text"
                    id="searchInput"
                    name="searchInput"
                    v-model="searchText"
                    required
                />
            </div>

            <div class="flex gap-5 w-[70%] justify-end items-center">
                <div class="w-[20%]">
                    <Listbox v-model="selectedSortOption">
                        <div class="relative mt-1">
                            <ListboxButton
                                class="relative w-full cursor-default !border !border-[1.5px] !border-[rgb(228, 228, 231)] rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                            >
                                <span class="block truncate">{{ selectedSortOption?.name }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    <ListboxOption v-slot="{ active, selected }" v-for="option in sortOptions" :key="option?.name" :value="option" as="template">
                                        <li :class="[active ? 'bg-[#d5efff] text-[#2196f3]' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                                            <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option?.name }}</span>
                                            <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-[#2196f3]">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>

                <div class="w-[20%]">
                    <Listbox v-model="selectedOption">
                        <div class="relative mt-1">
                            <ListboxButton
                                class="relative w-full cursor-default !border !border-[1.5px] !border-[rgb(228, 228, 231)] rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                            >
                                <span class="block truncate">{{ selectedOption?.status }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    <ListboxOption v-slot="{ active, selected }" v-for="option in options" :key="option?.status" :value="option" as="template">
                                        <li :class="[active ? 'bg-[#d5efff] text-[#2196f3]' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                                            <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option?.status }}</span>
                                            <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-[#2196f3]">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
            </div>
        </div>
        <div class="min-h-[700px] h-[fit-content] max-h-[800px] overflow-y-auto">
            <table class="min-w-full text-sm">
                <thead class="text-gray-700 w-full bg-[#FBFBFB]">
                    <tr class="border-b transition-colors hover:bg-muted/50">
                        <th scope="col" class="h-14 text-[#64748B] px-4 text-left font-medium text-muted-foreground">{{ $t("Name") }}</th>
                        <th v-for="(assessment, index) in filteredResults" :key="index" class="h-14 text-[#64748B] px-4 align-middle font-medium text-muted-foreground">
                            {{ assessment.assessmentName }}
                        </th>
                        <th scope="col" class="h-14 text-[#64748B] text-center px-4 text-left font-medium text-muted-foreground">{{ $t("Overall Score") }}</th>
                    </tr>
                </thead>
                <tbody v-if="filtredCandidates.length > 0">
                    <tr
                        v-for="(candidate, index) in filtredCandidates"
                        :key="index"
                        class="border-b-[1px] border-[#e5e7eb] transition duration-300 ease-in-out hover:bg-[#2371b631] text-gray-700 duration-300 cursor-pointer"
                    >
                        <td
                            @click="
                                () => {
                                    const locale = this.$route.params.locale || 'en';
                                    this.$router.push({
                                        path: `/${locale}/candidate`, // Include the locale in the path
                                        query: { email: candidate.candidate, projectId: candidate?.projectId },
                                    });
                                }
                            "
                            class="p-3 align-middle"
                        >
                            {{ candidate.name }}
                        </td>
                        <td
                            v-for="(result, index) in filterResults(candidate?.results)"
                            :key="index"
                            class="p-3 align-middle overflow-x-scroll"
                            @click="
                                () => {
                                    const locale = this.$route.params.locale || 'en';
                                    this.$router.push({
                                        path: `/${locale}/candidate`, // Include the locale in the path
                                        query: { email: candidate.candidate, projectId: candidate?.projectId },
                                    });
                                }
                            "
                        >
                            <div v-if="result.personalityResults" class="grid grid-cols-5 gap-1">
                                <div
                                    v-for="(personalityResult, index) in getTraits(result.personalityResults)"
                                    :key="index"
                                    class="flex items-center justify-center w-[25px] h-[25px] rounded-[5px]"
                                    :class="{
                                        'bg-[#EA4745] text-white': personalityResult.degree === 'Very Low',
                                        'bg-[#ffa500]': personalityResult.degree === 'Low',
                                        'bg-[#ffff00]': personalityResult.degree === 'Medium',
                                        'bg-[#46A997] text-white': personalityResult.degree === 'High',
                                        'bg-[#008000]': personalityResult.degree === 'Very High',
                                    }"
                                >
                                    <p>{{ personalityResult.name.charAt(0) }}</p>
                                </div>
                            </div>
                            <div v-else class="flex justify-center items-center">
                                <p v-if="result.totalPoints">
                                    <CircleSlider :value="parseFloat(((result.totalPoints * 100) / result.quesionsNbr).toFixed())" />
                                </p>
                                <div v-else class="flex justify-center items-center">
                                    <p v-if="result.rangesPoint">
                                        <CircleSlider :value="parseFloat(((result.rangesPoint / (result.quesionsNbr * 5)) * 100).toFixed())" />
                                    </p>

                                    <p v-else class="flex justify-center items-center">
                                        <CircleSlider :value="0" />
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td
                            @click="
                                () => {
                                    const locale = this.$route.params.locale || 'en';
                                    this.$router.push({
                                        path: `/${locale}/candidate`, // Include the locale in the path
                                        query: { email: candidate.candidate, projectId: candidate?.projectId },
                                    });
                                }
                            "
                            class="p-3 align-middle"
                        >
                            <div class="flex justify-center items-center">
                                <CircleSlider :value="parseFloat(calculateOverAllScore(candidate?.results))" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="filtredCandidates.length === 0" class="w-full my-5 text-center text-[16px] text-[#1b2559]">{{ $t("No Candidates") }}</div>
        </div>
    </div>
    <!--<div v-else class="w-full text-center p-3 text-[#0f172a] text-[20px]" style="font-weight: 500">No Candidate assessed
        yet</div>-->
    <!--<div v-else class="w-full mb-3 flex justify-center items-center">-->
    <div v-else class="w-full mb-3 flex flex-col justify-center items-center">
        <img src="@/assets/No-Candidates-Found.png" alt="No Candidates Found" class="w-[180px] h-auto mb-6" />
        <div class="text-center">
            <span class="emptyStateTitle">No Candidates Found</span>
        </div>
        <p class="max-w-md mx-auto text-center my-4 text-sm opacity-75">Looks like you haven’t invite any candidates. We advise share a link with candidates or invite manually.</p>
        <button class="nextStep shadow" @click="toggleEmail">{{ $t("Use Link") }}</button>
    </div>
</template>

<script>
import CircleSlider from "../../CircleSlider";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
export default {
    name: "CandidatesTable",
    components: {
        CircleSlider,
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon,
        ChevronUpDownIcon,
    },
    props: {
        candidates: {
            type: Array,
            required: true,
        },
        projectAssessments: {
            type: Array,
            required: true,
        },
        minScore: {
            type: Number,
            required: true,
        },
        toggleEmail: {
            type: Function,
        },
    },
    data() {
        return {
            searchText: "",
            filtredCandidates: this.candidates,
            selectedOption: null,
            selectedSortOption: null,
            options: [{ status: "All" }, { status: "passed" }, { status: "Failed" }],
            sortOptions: [{ name: "Best Ranked" }, { name: "Worst Ranked" }], // sort options
        };
    },
    methods: {
        getTraits(results) {
            return Object.keys(results).map((key) => {
                return { name: key, degree: results[key] };
            });
        },
        filterResults(results) {
            return results.filter((result) => !Object.prototype.hasOwnProperty.call(result, "customResults"));
        },
        sortCandidates() {
            // Sort candidates based on the selected sort option
            if (this.selectedSortOption?.name === "Best Ranked") {
                this.filtredCandidates.sort((a, b) => this.calculateTotalPoints(b) - this.calculateTotalPoints(a));
            } else if (this.selectedSortOption?.name === "Worst Ranked") {
                this.filtredCandidates.sort((a, b) => this.calculateTotalPoints(a) - this.calculateTotalPoints(b));
            }
        },
        calculateTotalPoints(candidate) {
            let totalPoints = 0;
            candidate.results.forEach((result) => {
                if (result.totalPoints) {
                    totalPoints += result.totalPoints;
                }
            });
            return totalPoints;
        },
        calculateOverAllScore(results) {
            if (!Array.isArray(results) || results.length === 0) return 0;
            // Filter out results with 'personalityResults'
            const filteredResults = results.filter((result) => !result.personalityResults && !result.customResults);
            let score = 0;
            filteredResults.forEach((result) => {
                const assessment = this.projectAssessments.find((assessment) => assessment.name === result.assessmentName);

                if (result.totalPoints) {
                    // score = result.totalPoints;
                    // possibleScore = result.quesionsNbr; // Max possible score is the number of questions
                    score += ((result.totalPoints * 100) / result.quesionsNbr) * (assessment.weight || 1);
                } else if (result.rangesPoint) {
                    score += (result.rangesPoint / (result.quesionsNbr * 5)) * 100 * (assessment.weight ? assessment.weight : 1);
                }
            });
            let totalWeights = 0;

            this.projectAssessments.filter((assess) => assess.category != "Custom" && assess.category != "Personality").forEach((assess) => (totalWeights += 1 * (assess.weight || 1)));

            // Avoid division by zero
            let finalScore = score / totalWeights;

            return finalScore.toFixed(0);
        },
    },
    watch: {
        selectedSortOption: function () {
            this.sortCandidates(); // Call the sorting method when a new sort option is selected
        },
        searchText: function (val) {
            this.filtredCandidates = this.candidates.filter((candidate) => candidate.name.toLowerCase().includes(val.toLowerCase()));
        },
        selectedOption: function (val) {
            switch (val.status) {
                case "All":
                    this.filtredCandidates = this.candidates;
                    break;
                case "passed":
                    this.filtredCandidates = this.candidates.filter((candidate) => {
                        // let score = 0;

                        // candidate?.results.forEach((element) => {
                        //     if (element.rangesPoint || element.totalPoints) {
                        //         score += element.rangesPoint ? (element.rangesPoint * 100) / (element.quesionsNbr * 5) : (element.totalPoints * 100) / element.quesionsNbr;
                        //     }
                        // });
                        let myScore = this.calculateOverAllScore(candidate.results);
                        const averageScore = myScore;
                        // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage
                        return averageScore >= this.minScore; // Filter candidates with scores over 80%
                    });
                    break;
                case "Failed":
                    this.filtredCandidates = this.candidates.filter((candidate) => {
                        // let score = 0;
                        // candidate?.results.forEach((element) => {
                        //     if (element.rangesPoint || element.totalPoints) {
                        //         score += element.rangesPoint ? (element.rangesPoint * 100) / (element.quesionsNbr * 5) : (element.totalPoints * 100) / element.quesionsNbr;
                        //     }
                        // });
                        // const averageScore = score / candidate?.results.length;
                        // // const roundedScore = (averageScore * 100).toFixed(2); // Convert to percentage
                        let myScore = this.calculateOverAllScore(candidate.results);
                        const averageScore = myScore;
                        return averageScore < this.minScore; // Filter candidates with scores over 80%
                    });

                    break;
                default:
                    this.filtredCandidates = this.candidates;
            }
        },
    },
    mounted() {
        this.selectedOption = this.options[0];
        this.selectedSortOption = this.sortOptions[0];
    },
    computed: {
        sortedCandidates() {
            return this.filtredCandidates;
        },
        filteredResults() {
            // Check if candidates array and its first element are defined
            if (!this.candidates || !this.candidates[0]) {
                return [];
            }

            // Filter out objects that contain the 'customResults' attribute
            return this.candidates[0]?.results.filter((result) => !Object.prototype.hasOwnProperty.call(result, "customResults"));
        },
        filteredCandidateResult(results) {
            return results?.filter((result) => !Object.prototype.hasOwnProperty.call(result, "customResults"));
        },
    },
};
</script>

<style scoped lang="scss">
/* Ensure table header sticks to the top */

/* Ensure first column sticks to the left */
// td:first-child,
// th:first-child {
//     position: sticky;
//     left: 0;
//     background-color: white;
//     z-index: 10;
// }
/* Define a class for columns that should scroll horizontally */
.overflow-x-scroll {
    overflow-x: auto;
    white-space: nowrap;
}

.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.emptyStateTitle {
    background: linear-gradient(to right, #2196f3, #c084fc);
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text color transparent */
    background-clip: text; /* Standard property for background clipping */
    text-fill-color: transparent; /* Standard property for text fill color */

    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}

.nextStep {
    // width: 100px;
    height: 45px;
    color: white;
    font-weight: 500;
    background: #2196f3;
    border-radius: 5px;
    padding: 0 4%;
    &:hover {
        opacity: 0.85;
    }
}

.selectWrapper {
}
</style>
