<template>
    <div class="assessment-preview" v-if="showPreview">
        <div class="preview-close" @click="togglePreview"></div>

        <div class="assessmentPopup">
            <div class="closeBtnWrapper">
                <div class="w-[30%] flex gap-5">
                    <button class="selectBtn" @click="navigateToPreview"><font-awesome-icon :icon="['far', 'eye']" /> {{ $t("Preview") }}</button>

                    <button @click="addAssessment(assessment)" class="selectBtn" v-if="this.$route.path !== '/library'">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25px"
                            height="25px"
                            viewBox="0 0 24 24"
                            class="stroke-black fill-none group-active:stroke-blue-200 group-active:fill-white group-active:duration-0 duration-300"
                        >
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke-width="1.5"></path>
                            <path d="M8 12H16" stroke-width="1.5"></path>
                            <path d="M12 16V8" stroke-width="1.5"></path>
                        </svg>
                        {{ $t("Select") }}
                    </button>
                </div>
                <button @click="togglePreview" class="nav-btn trash-btn">
                    <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                    <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                </button>
            </div>
            <div class="assessmentDesc">
                <div>
                    <div class="preview-header">
                        {{ this.assessment.name }}
                    </div>
                    <div class="details">
                        <div>
                            <font-awesome-icon class="icon" :icon="['fas', 'list-check']" />
                            <h2>Type</h2>
                            <p>{{ assessment.category }}</p>
                        </div>
                        <div>
                            <font-awesome-icon class="icon" :icon="['far', 'clock']" />
                            <h2>Time</h2>
                            <p>{{ assessment.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment.questions_nbr * 35) / 60) }}min</p>
                        </div>
                        <div>
                            <font-awesome-icon class="icon" :icon="['fas', 'globe']" />
                            <h2>Language</h2>
                            <p>English, {{ !assessment?.category?.toLowerCase().includes("hard") ? "French" : "" }}</p>
                        </div>
                        <div>
                            <!-- <img loading="lazy"  decoding="async" src="@/assets/levelIcon.svg" alt="" width="20" height="20" /> -->
                            <font-awesome-icon class="icon" :icon="['far', 'star']" />
                            <h2>{{ $t("Recommanded Score") }}</h2>
                            <p>80%</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="preview-description">
                        <h2 style="font-size: 16px">{{ $t("Description") }}:</h2>
                        <span style="font-size: 16px" v-html="formattedText"></span>
                    </div>
                    <div class="aboutCreator">
                        <h2 style="font-size: 16px">{{ $t("About the subject-matter expert") }}:</h2>
                        <div class="about">
                            <img loading="lazy" decoding="async" src="@/assets/Images/go_logo-copy.svg" alt="" />
                            <div>
                                <h2>{{ $t("GO platform team") }}</h2>
                                <span> {{ assessment.description_test !== "" ? assessment.description_test : assessment.description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "@/store";
import axios from "axios";

export default {
    name: "AssessmentPreview",
    props: ["assessment", "showPreview", "togglePreview", "addAssessment"],
    data() {
        return {
            /*assessment: {
                      name:"Javascript Assessment",
                      description:"lorem ipsum dollor Now that you have some icons on the page, add some pieces of flair! Check out\
                                   all the styling options you can use with Font Awesome and React.lorem ipsum dollor Now that you \
                                   have some icons on the page, add some pieces of flair! Check out all the styling options you can \
                                   use with Font Awesome and React. lorem ipsum dollor Now that you have some icons on the page, add \
                                   some pieces of flair! Check out all the styling options you can use with Font Awesome and React.",
                      qst_num: 12,
                      duration: 12,
                      score: 70,
                  }*/
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        addParagraphBreaks(longText) {
            // Split the text into paragraphs using double line breaks
            var paragraphs = longText?.split(". ");

            // Join paragraphs with <br/> tags between them
            var formattedText = paragraphs?.join(".<br/><br/>");

            return formattedText;
        },
        addAssessments(assessment) {
            let data = JSON.stringify({
                assessmentId: assessment.id,
                companyId: "431",
                recommanded_score: assessment.score,
                qst_num: assessment.qst_num,
                name: assessment.name,
                duration: assessment.duration,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/AssessmentTest/companyAssessment",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
            // console.log({ assessmentId: assessment.id });
        },
        navigateToPreview() {
            const locale = this.$route.params.locale || "en"; // Get the current locale or fallback to "en"

            const url = this.$router.resolve({
                path: `/${locale}/previewAssessment`,
                query: { id: this.assessment._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
    },
    computed: {
        formattedHeader() {
            // Use the same transformation logic as before
            const nameArray = this.assessment.name.split(/-/);

            // Capitalize the first letter of each word and remove "-"
            const transformedArray = nameArray.map((part) => {
                const words = part.split(/\s+/);
                return words
                    .map((word) => {
                        // Check if the word is in the list of words to be capitalized entirely
                        const capitalWords = ["kpmg", "dragnet", "gtco", "nnpc", "pwc", "zenithbank", "xml", "aws", "vba"];

                        // Capitalize the entire word if the lowercase version is in the list
                        if (capitalWords.includes(word?.toLowerCase())) {
                            return word.toUpperCase();
                        }

                        // Capitalize the first letter of each word
                        return word.charAt(0).toUpperCase() + word?.slice(1)?.toLowerCase();
                    })
                    .join(" ");
            });

            // Join the parts into a sentence
            return transformedArray.join(" ");
        },
        formattedText() {
            return this.assessment.description_test !== "" ? this.addParagraphBreaks(this.assessment.description_test) : this.addParagraphBreaks(this.assessment.description);
        },
    },
};
</script>

<style lang="scss" scoped>
.assessment-preview {
    position: fixed;
    z-index: 20;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0000003f;
    /*grid-template-areas:
      'head  head'
      'description  cards'
      'btns btns';
      grid-template-rows: 1fr 3fr 1fr;
      grid-template-columns: 5fr 2fr;*/

    // .preview-close {
    //   position: fixed;
    //   z-index: -1;
    //   bottom: 0;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   background: rgba(0, 0, 0, 0.4);
    // }
    > :nth-child(2) {
        // border-radius: 10px;
        // padding: 2rem 3rem;
        // width: 50%;
        // height: fit-content;
        // display: flex;
        // flex-direction: column;
        // background: #fff;
        // align-self: center;
        // box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);
        // gap: 1rem;
        // color: #050708;

        .preview-header {
            position: relative;
            //grid-area: head;
            font-size: 36px;
            font-weight: 600;
            line-height: 120%;
            color: #2196f3;
            padding-bottom: 1rem;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background: #c3cad9;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .preview-description {
            display: flex;
            flex-direction: column;
            //grid-area: description;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            > span {
                font-weight: 400;
                padding: 1rem;
            }
        }

        .preview-cards {
            //grid-area: cards;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            gap: 1rem;
            /*border: 1px solid #C3CAD9;
          border-radius: 20px;
          box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);*/
            padding: 1rem 0;

            > * {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: center;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 2rem;

                    font-weight: 700;
                    font-size: 30px;
                    line-height: 22px;
                    text-align: center;
                    padding: 0.5rem;
                    color: #2196f3;
                }
            }
        }

        .preview-btn {
            //grid-area: btns;
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 45px;
            justify-content: center;
            gap: 1rem;
            align-items: flex-end;

            > * {
                width: 20%;
                height: 100%;
                padding: 0.25rem;
                border-radius: 20px;
                color: #fff;
                background: #2196f3;
                border: none;
                font-weight: 700;
                font-size: 22px;
                line-height: 20px;
                text-align: center;
                cursor: pointer;
                transition: all 0.4s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }

            > :first-child {
                background: #fff;
                font-weight: 500;
                box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
                color: #050708;
                border: 1px solid #c3cad9;
            }
        }
    }

    .assessmentPopup {
        border-radius: 10px;
        padding: 5rem;
        padding-top: 0;
        width: 96%;
        height: 90%;
        overflow-y: scroll;
        background: #f6f6f6;
        align-self: center;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);

        color: #050708;

        .assessmentDesc {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 5rem;
        }

        .details {
            width: 100%;
            margin-top: 5%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 3px;

            div {
                padding: 8%;
                background-color: #fff;
            }
        }

        .closeBtnWrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 5rem 0;
            margin-top: 2rem;

            .trash-btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .nav-icon {
                    color: red;
                }
            }

            .trash-btn:hover {
                border: 2px solid red;
            }

            .selectBtn {
                padding: 0.5rem 2rem;
                border-radius: 10px;
                background-color: #ededed;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .aboutCreator {
        margin-top: 3%;

        h2 {
            font-size: 16px;
            font-weight: 600;
        }

        .about {
            display: grid;
            grid-template-columns: 1fr 5fr;
            margin-top: 2%;

            img {
                width: 75px;
                height: 75px;
                margin-left: 50%;
                transform: translateX(-50%);
            }

            span {
                font-size: 14px;
            }
        }
    }
}

.icon {
    color: #2196f3;
}
</style>
