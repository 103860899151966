<template>
    <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[1000] bg-black bg-opacity-30">
        <div class="w-[60%] h-fit max-h-[90%] overflow-y-auto bg-white rounded-md p-8">
            <div class="flex justify-between mb-10">
                <h1 class="text-[26px] font-bold">Set test weights</h1>
                <font-awesome-icon @click="toggleWeighting" :icon="['fas', 'xmark']" class="text-[20px]" />
            </div>
            <div class="font-semibold my-6">
                <p>
                    Prioritize the tests that are most important to you by assigning them a weight. The weight of a test directly impacts your average score.
                    <br />
                    <br />
                    You can assign a weight value between x1 and x5 to each test. A test with a weight of x5 will boost the final score five times more than a test with a weight of x1.
                </p>
            </div>
            <div class="table-container w-full">
                <table>
                    <thead>
                        <tr>
                            <th>Tests</th>
                            <th class="">Weights</th>
                            <th>Impact</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                <div class="flex gap-[1px] text-sm my-1">
                                    <span v-for="weight in 5" :key="weight" class="w-[30px] flex justify-center"> x{{ weight }} </span>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr v-for="(test, index) in tests.filter((test) => test.category !== 'Custom')" :key="index">
                            <td>{{ test.name }}</td>
                            <td class="weights h-full">
                                <span v-for="weight in 5" :key="weight" class="square" :class="{ active: test.weight >= weight }" @click="setWeight(index, weight)"> </span>
                            </td>
                            <td>{{ calculateImpact(index) }}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-end w-full mt-8">
                <div class="w-[40%] flex gap-2 justify-end items-center">
                    <ButtonComponent :action="toggleWeighting" intent="secondary">
                        {{ $t("Cancel") }}
                    </ButtonComponent>
                    <!-- <ButtonComponent :action="saveWeights" intent="primary">
                        {{ $t("Save") }}
                    </ButtonComponent> -->
                    <ButtonComponent :loading="isLoading" :action="saveWeights" intent="primary">
                        {{ $t("Save") }}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "@/components/ReusableComponents/ButtonComponent.vue";
import axios from "axios";
export default {
    name: "AssessmentsWeight",
    components: {
        ButtonComponent,
    },
    props: {
        assessments: {
            type: Array,
        },
        toggleWeighting: {
            type: Function,
        },
        projectId: {
            type: String,
        },
    },
    data() {
        return {
            tests: this.assessments.map((test) => ({
                ...test,
                weight: test.weight ?? 1,
            })),
            isLoading: false,
        };
    },
    methods: {
        setWeight(index, weight) {
            this.tests[index].weight = weight;
        },
        calculateImpact(index) {
            let totalWeight = this.tests.filter((test) => test.category !== "Custom").reduce((sum, test) => sum + test.weight, 0);
            return totalWeight ? Math.round((this.tests[index].weight / totalWeight) * 100) : 0;
        },
        async saveWeights() {
            this.isLoading = true;
            try {
                await axios.put(
                    "https://server.go-platform.com/projects/update-weighting",
                    {
                        projectId: this.projectId, // Ensure this is correctly set
                        assessments: this.tests, // Ensure `this.tests` has the correct structure
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true, // Ensures cookies are sent
                    },
                );
                window.location.reload();
                this.isLoading = false;
            } catch (error) {
                console.error("Error updating assessments:", error);
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-container {
    // max-width: 500px;
    margin: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.weights {
    display: flex;
    gap: 1px;
}

.square {
    width: 30px;
    height: 25px;
    background: #eee;
    // border-radius: 4px;
    cursor: pointer;
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.square.active {
    background: #2196f3;
}
</style>
