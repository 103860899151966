<template>
    <div class="bg-[#fff] mt-5 rounded-md px-2 shadow-card w-full">
        <div v-if="histogramData.length > 0">
            <div class="score-head">
                <div class="flex flex-row content-start items-center w-[18%]">
                    <h3 class="tet-[20px]">{{ $t("Talents") }}</h3>
                </div>
                <span class="on-track">
                    <img loading="lazy" decoding="async" src="@/assets/Images/icons/check-green.svg" alt="on-track" />
                    {{ $t("On Track") }}
                </span>

                <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/bar-chart-icon.svg" alt="" /> -->
            </div>

            <div class="chart-container">
                <HistogramVue :histogramData="this.histogramData" :chartWidth="650" :chartHeight="350" :showLabel="true" class="hidden lg:flex" />
                <div class="w-full flex lg:hidden justify-center items-center text-lg text-center font-bold text-slate-700">For better visibility and insights use a laptop or desktop computer</div>
                <!-- <svg class="chart-svg"></svg> -->
            </div>
            <!-- <button class="viewbtn">{{ $t("View All") }} <img loading="lazy"  decoding="async" src="@/assets/Images/icons/Vector.svg" alt="" /></button> -->
        </div>
        <div class="w-full px-8 flex my-4 flex-col items-center" v-else>
            <img src="@/assets/cuate.png" alt="No Data Available" class="w-[200px] h-auto mb-6" />
            <div class="text-center">
                <span class="recentapp">Invite More Candidates</span>
            </div>
            <p class="max-w-md text-center text-sm opacity-75 my-4">Looks like you haven’t invite Enough candidates. Try Invite Candidates.</p>
            <button class="nextStep2 shadow whitespace-nowrap" @click="toggleEmail">{{ $t("Use Link") }}</button>
        </div>
    </div>
</template>

<script>
// import * as d3 from "d3";
import HistogramVue from "@/charts/Histogram.vue";
export default {
    name: "CandidatesChart",
    components: { HistogramVue },
    props: {
        histogramData: Array,
        toggleEmail: Function,
    },
    data() {
        return {};
    },
    // mounted() {
    //     const containerWidth = 500;
    //     const containerHeight = 200; // Set the desired height for the chart

    //     const margin = { top: 20, right: 20, bottom: 40, left: 50 };
    //     const width = containerWidth - margin.left - margin.right;
    //     const height = containerHeight - margin.top - margin.bottom;

    //     const svg = d3.select(".chart-svg").attr("width", containerWidth).attr("height", containerHeight);

    //     const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //     const data = [
    //         { category: "Invited", value: 4 },
    //         { category: "Email Delivered", value: 3 },
    //         { category: "Emails Opened", value: 5 },
    //         { category: "Links Clicked", value: 2 },
    //         { category: "Attempted", value: 3.5 },
    //         { category: "Passed", value: 4 },
    //     ];

    //     const x = d3
    //         .scaleBand()
    //         .domain(data.map((d) => d.category))
    //         .range([0, width])
    //         .padding(0.3);

    //     const y = d3
    //         .scaleLinear()
    //         .domain([0, d3.max(data, (d) => d.value)])
    //         .range([height, 0]);

    //     g.selectAll(".bar")
    //         .data(data)
    //         .enter()
    //         .append("rect")
    //         .attr("class", "bar")
    //         .attr("x", (d) => x(d.category))
    //         .attr("y", (d) => y(d.value))
    //         .attr("width", x.bandwidth())
    //         .attr("height", (d) => height - y(d.value))
    //         .attr("fill", "#2196f3")
    //         .attr("rx", 8) // Add border radius to the bars
    //         .attr("ry", 8);

    //     // Add x-axis
    //     g.append("g")
    //         .attr("transform", "translate(0," + (height + 20) + ")")
    //         .call(d3.axisBottom(x))
    //         .call((g) => g.select(".domain").remove())
    //         .call((g) => g.selectAll(".tick line").remove()); // remove ticks;

    //     // Add y-axis
    //     g.append("g")
    //         .call(d3.axisLeft(y).ticks(5))
    //         .call((g) => g.select(".domain").remove())
    //         .call((g) => g.selectAll(".tick line").remove()); // remove ticks;

    //     // Style the axes
    //     g.selectAll(".tick line").attr("stroke", "#ccc");

    //     g.selectAll("text").attr("fill", "#929EAE");

    //     // Append a line element for the dashed line
    //     const maxValue = d3.max(data, (d) => d.value);
    //     g.append("line").attr("class", "highlight-line").attr("x1", 0).attr("x2", width).attr("y1", y(maxValue)).attr("y2", y(maxValue));

    //     // Style the dashed line
    //     g.select(".highlight-line").attr("stroke", "#2196f3").attr("stroke-dasharray", "5,5"); // Set the dash pattern (5px dash, 5px gap)
    // },
};
</script>

<style scoped lang="scss">
.candidate {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 6px;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.recentapp {
    background: linear-gradient(to right, #2196f3, #c084fc);
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text color transparent */
    background-clip: text; /* Standard property for background clipping */
    text-fill-color: transparent; /* Standard property for text fill color */

    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}
.nextStep2 {
    height: 45px;
    color: white;
    font-weight: 500;
    background: #2196f3;
    border-radius: 5px;
    padding: 0 4%;

    &:hover {
        opacity: 0.85;
    }
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.score-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0;
    padding: 0;

    h3 {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        margin: 0;
    }
}

.score-head > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center;
}

.score-head > div > span {
    color: #05cd99;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
    width: 50px;
    height: 50px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
    gap: 8px;
}

.chart-container {
    width: 100%;
    height: 300px;
    /* Set the desired height for the chart */
    display: flex;
    justify-content: center;
    padding: 3% 0;
    margin: 0;
}

.chart-svg {
    width: 100%;
    height: 100%;
}

.svgBG {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
</style>
