<template>
    <div class="fixed h-[100%] w-[100%] bg-[#00000015] top-0 left-0 z-20 flex items-center justify-center" v-if="viewAllRatings">
        <div class="absolute top-0 left-0 w-full h-full z-[-1]" @click="toggleRatings"></div>
        <div class="bg-white rounded-[5px] w-[40%] h-[fit-content] max-h-[75%] overflow-y-scroll grid gap-[10px] p-3">
            <CandidatesRating v-for="(candidateRating, i) in candidatesRating" :candidateRating="candidateRating" :key="i" />
        </div>
    </div>
</template>

<script>
import CandidatesRating from "./CandidatesRating.vue";

export default {
    name: "AllRatings",
    props: {
        viewAllRatings: Boolean,
        toggleRatings: Function,
        candidatesRating: Array,
    },
    components: { CandidatesRating },
    watch: {
        // candidatesRating: {
        //     handler(newVal) {
        //     },
        //     immediate: true, // Trigger the watcher immediately when the component is created
        // },
    },
};
</script>

<style scoped lang="scss"></style>
