<template>
    <div class="carousel">
        <div class="slider" ref="slider">
            <!--:style="{transform: 'translateX(' + translateValue + 'px)'}"-->
            <Transition :name="transitionEffect">
                <div v-show="currentIndex === index" class="img_container">
                    <img
                        loading="lazy"
                        :src="require(`@/assets/Images/icons/${image}`)"
                        class="slide image"
                        v-for="(image, index) in Images"
                        :key="index"
                        ref="slide"
                        alt=""
                        width="500"
                        height="300"
                        v-show="currentIndex === index"
                    />
                </div>
            </Transition>
        </div>
        <button class="prev" @click="prev">&#60;</button>
        <button class="next" @click="next">&#62;</button>
    </div>
</template>

<script>
export default {
    name: "imageCarousel",
    props: {
        Images: { type: Array, required: true },
    },
    data() {
        return {
            currentIndex: 0,
            translatevalue: 0,
            direction: "right",
        };
    },
    methods: {
        prev() {
            const index = this.currentIndex > 0 ? this.currentIndex - 1 : this.Images.length - 1;
            this.setCurrentIndex(index);
            this.direction = "left";
            //this.startSliderTimer();
            /*if (this.currentIndex === 0){
                    this.currentIndex = this.Images.length - 1;
                } else {
                    this.currentIndex --;
                }
                this.translatevalue += this.slideWidth();*/
        },
        /* next(step = 1){
                this._next(step)
                //this.startSliderTimer()
               /* if( this.currentIndex === this.Images.length -1){
                    this.currentIndex = 0;
                } else{
                    this.currentIndex++;
                }
                this.translatevalue -= this.slideWidth();
            },*/
        next() {
            const index = this.currentIndex < this.Images.length - 1 ? this.currentIndex + 1 : 0;
            this.setCurrentIndex(index);
            this.direction = "right";
        },
        setCurrentIndex(index) {
            this.currentIndex = index;
        },
        /* startSliderTimer(){
                this.stopSlidetimer()
                this.
            },*/
        slideWidth() {
            return this.$refs.slide.clientWidth;
        },
    },
    mounted() {},
    computed: {
        transitionEffect() {
            return this.direction === "right" ? "image-out" : "image-in";
        },
    },
};
</script>

<style lang="scss" scoped>
.carousel {
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    //div{border: 2px solid #000;}

    .slider {
        width: 100%;
        height: 25rem;
        overflow: hidden;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img_container {
            height: 100%;

            .slide {
                flex: 0 0 500px;
                height: 100%;
                width: 100%;
                margin-right: 2rem;
                object-fit: fill;
            }
        }
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 18px;

        &:hover {
            background: rgba(0, 0, 0, 0.5);
        }
    }

    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }
}

.image-in-enter-active {
    animation: slide-left-in all 1s ease-in-out;
}

.image-in-leave-active {
    animation: slide-left-out all 1s ease-in-out;
}

@keyframes image-left-in {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes image-left-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

.image-out-enter-active {
    animation: slide-right-in 1s all ease-in-out;
}

.image-out-leave-active {
    animation: slide-right-out all 1s ease-in-out;
}

@keyframes image-right-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(50%);
    }
}

@keyframes image-right-in {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(0);
    }
}
</style>
