<template>
    <div v-if="show" class="pop_up">
        <div class="exit_zone" @click="toggleModal"></div>
        <div class="login-container">
            <!--   -->
            <span class="logo">
                <img loading="lazy" decoding="async" src="@/assets/Images/go_logo.svg" alt="Recruitable" width="200" height="120" class="thelogo" />
            </span>
            <Transition name="slide-fade">
                <div class="log_in" v-if="!psswrd_forgot">
                    <form action="" @submit.prevent="login">
                        <span class="login-span">{{ $t("Log in to your account") }}</span>
                        <div class="input_group">
                            <label class="email-label" for="log_email">{{ $t("Email") }} </label>
                            <input type="email" name="log_email" id="log_email" placeholder="example@example.com" v-model="email" />
                        </div>
                        <div class="input_group pad_right_0">
                            <label for="password">{{ $t("Password") }} </label>
                            <div>
                                <input :type="showPassword ? 'text' : 'password'" name="password" id="password" v-model="password" placeholder="*********" /><button
                                    @click.prevent="showPassword = !showPassword"
                                >
                                    <img
                                        loading="lazy"
                                        decoding="async"
                                        class="eye-icon"
                                        :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                        width="25"
                                        height="25"
                                        alt=""
                                    />
                                </button>
                            </div>
                            <button class="forgot-pass" @click.prevent="psswrd_forgot = !psswrd_forgot">
                                {{ $t("Forgot Password?") }}
                            </button>
                        </div>

                        <button v-if="!loading" type="submit" value="Log in" class="loginBtn">{{ $t("Log in") }}</button>
                        <button v-else value="Log in" class="loginBtn loading-btn">
                            <LoaderComponent />
                        </button>
                        <p v-if="wrongCredentials" class="text-left text-red-500" style="font-size: 14px">Please check your email and password</p>
                    </form>
                    <span class="sign_up"
                        >{{ $t("Already have an account?") }}
                        <router-link to="/register" @click="closeLogIn">{{ $t("Sign Up") }}</router-link>
                    </span>
                </div>
            </Transition>
            <Transition name="slide-fade">
                <div class="log_in" v-if="psswrd_forgot">
                    <form action="" @submit.prevent="resetPassword">
                        <span>{{ $t("Forgot Password?") }}</span>
                        <div class="input_group relative">
                            <label for="email_forgot">{{ $t("Email Address") }}</label>
                            <input type="email" name="email_forgot" id="email_forgot" v-model="resetPassEmail" :placeholder="$t('Fill in your Email')" />
                            <span v-if="resetEmailRequired" class="err_msg">{{ resetEmailRequired }}</span>
                        </div>
                        <div class="captcha">
                            <VueRecaptcha :sitekey="sitekey" size="normal" badge="bottomright" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify"> </VueRecaptcha>
                        </div>
                        <div class="btns">
                            <button @click.prevent="psswrd_forgot = !psswrd_forgot">{{ $t("Cancel") }}</button>
                            <button v-if="!loading" @click="resetPassword">{{ $t("Next") }}</button>

                            <button v-else value="Log in" class="loginBtn loading-btn">
                                <LoaderComponent />
                            </button>
                        </div>
                        <p v-if="resetPasswordMessage" class="text-right text-green-500" style="font-size: 14px">{{ this.resetPasswordMessage }}</p>
                    </form>
                    <a class="no_access" href="">{{ $t("Don't have access to these informations?") }}</a>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import { useStore } from "../store/index";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
    name: "logIn",
    components: { VueRecaptcha, LoaderComponent },
    props: {
        show: Boolean,
        toggleModal: Function,
        userLoggedIn: Function,
    },
    data() {
        return {
            showPassword: false,
            psswrd_forgot: false,
            sitekey: "6LeAEmcnAAAAAERPmvDElaSYYb9Zyw7YP-W_MOAu",
            email: "",
            password: "",
            loading: false,
            wrongCredentials: false,
            resetPassEmail: "",
            resetEmailRequired: "",
            resetPasswordMessage: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        closepop() {},
        closeLogIn() {
            this.$router.push("/register");
            this.toggleModal();
        },
        resetPassword() {
            this.resetEmailRequired = "";
            if (this.resetPassEmail) {
                const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

                if (!emailRegex.test(this.resetPassEmail)) {
                    this.resetEmailRequired = "Please enter a valid email address.";
                } else {
                    this.loading = true;
                    this.resetEmailRequired = "";

                    axios
                        .post(
                            "https://server.go-platform.com/user/changePassword",
                            { email: this.resetPassEmail },
                            {
                                withCredentials: true,
                            },
                        )
                        .then((response) => {
                            this.resetPasswordMessage = response.data.message;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            this.loading = false;
                            this.resetPasswordMessage = error.response.data.message;
                        });
                }
            }
        },

        login() {
            this.loading = true;
            let user = {
                email: this.email,
                password: this.password,
            };
            axios
                .post("https://server.go-platform.com/user/signin", user, {
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.status === 200) {
                        this.Store.isLoading = true;
                        this.$router.push(`/dashboard?company_name=${res.data.company_name}`);
                        // this.userLoggedIn();
                        this.Store.userLogged();
                        this.Store.getCompanyCredit();
                        if (res.data.role) {
                            this.$router.push(`/adminBlogs?company_name=${res.data.admin_name}`);
                        } else {
                            this.$router.push(`/dashboard?company_name=${res.data.company_name}`);
                        }

                        this.toggleModal();
                        // this.$emit("loggedIn");
                    }
                })
                .then(() => {
                    axios
                        .get("https://server.go-platform.com/user/isLoggedIn", {
                            withCredentials: true,
                        })
                        .then(() => {
                            this.$gtag.event("login", { method: "Google" });
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                })
                .catch((err) => {
                    this.wrongCredentials = true;
                    console.log("THE Error is here ---------------------", err);
                })
                .finally(() => (this.loading = false));
        },
        async forgotPassword(response) {
            const recaptchaToken = response;

            // Add reCAPTCHA token to your API request payload
            const forgotPasswordData = {
                email: this.email,
                recaptchaToken, // Add the reCAPTCHA token here
            };

            // Now you can make an API request to your backend to verify the reCAPTCHA token and handle the forgot password process.
            // For example, using Axios:
            try {
                await axios.post("https://server.go-platform.comhttps://server.go-platform.com/verify-recaptcha", forgotPasswordData);
            } catch (error) {
                console.error("Error:", error);
                // Handle any error that occurred during the API request
            }
        },
        onVerify(response) {
            // The reCAPTCHA response is passed as a parameter to the event handler
            this.forgotPassword(response);
        },
    },
    watch: {
        show() {
            this.psswrd_forgot = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.pop_up {
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    > :nth-child(2) {
        background: #fff;
        width: 40%;
        padding: 1rem 2rem;
        border-radius: 20px;
        height: 600px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        position: relative;

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            height: 60%;
        }

        .log_in {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: absolute;
            bottom: 15%;
            top: 25%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                color: #5a5a5d;

                > :first-child {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 14px;
                }

                /*>:nth-child(5){
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0 0;
                }*/
                .captcha {
                    padding: 1rem;

                    > :first-child {
                        > :first-child {
                            width: 100% !important;
                        }
                    }
                }
            }

            .sign_up {
                font-weight: 400;
                font-size: 19.96px;
                line-height: 100%;
                color: #4a5568;
                font-family: "Roboto";
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
            }

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                gap: 4rem;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
    }
}

.log_in span {
    margin-bottom: 20px;
}

.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.logo {
    font-weight: 900;
    font-size: 40px;
    line-height: 18px;
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 3rem;
    gap: 0.5rem;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
    }
}

.login-span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    color: #263238;
}

a {
    font-size: 18px;
}

input[type="submit"] {
    color: #fff;
    background: #2196f3;
    border-radius: 7px;
    border: none;
    width: 100%;
    padding: 1rem;
    font-weight: 600;
    font-size: 19.96px;
    line-height: 100%;
}

button {
    color: #2196f3;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button:hover {
    opacity: 0.85;
}

.font-icon {
    width: 13px;
    margin-left: 5px;
}

.font-icon-two {
    width: 12px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0;
    margin-top: 20px;

    label {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 5px;
    }

    input {
        border: 1px solid #e5e5ef;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        padding: 0 0.5rem;
        font-size: 16px;
    }

    div {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5ef;
        height: 2.5rem;
        border-radius: 4px;

        button {
            width: auto;
            height: fit-content;
            background: none;
        }

        input {
            // border: none;
        }
    }
}

.forgot-pass:hover {
    text-decoration: underline;
}

.forgot-pass {
    font-family: "Roboto";
    font-size: 16px;
}

.pad_right_0 {
    padding-right: 0;
}

.marge_3_0 {
    margin: 3rem 0;
}

.btns {
    display: flex;
    flex-flow: nowrap row;
    gap: 1.5rem;
    justify-content: flex-end;
    padding: 0 1rem;
    align-items: center;

    button {
        font-size: 21px;
        font-weight: 600;
        line-height: 20px;
        padding: 3% 0;
        width: 25%;
        justify-content: center;
    }

    > :first-child {
        background: none;
    }

    > :nth-child(2) {
        color: #fff;
        background: #2196f3;
        border-radius: 7px;
    }
}

.OR {
    color: #2196f3;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3%;
}

a {
    text-decoration: none;
    color: #2196f3;
}

.thelogo {
    margin-top: 20px;
}

.no_access {
    font-weight: 400;
    font-size: 19.96px;
    line-height: 100%;
    color: #bebebf;
}

.slide-fade-enter-active {
    transition: all 0.6s ease-in;
}

.slide-fade-leave-active {
    display: none;
    transition: all 0.4s ease cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
    transform: translateX(0);
    opacity: 0;
}

.eye-icon {
    width: 30px;
    padding: 0 3px;
}

.slide-fade-leave-to {
    transform: translateX(0);
    opacity: 0;
}

a:hover {
    text-decoration: underline;
}

.loginBtn {
    cursor: pointer;
    transition: 0.2s ease-in;
    background-color: #2196f3;
    color: white;
    width: 100%;
    padding: 12px;
    justify-content: center;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Roboto";
    height: 50px;
    font-weight: 600;

    &:hover {
        opacity: 0.85;
    }
}

.loading-btn {
    opacity: 0.85;
    align-items: center;
}

@media (min-width: 1230px) {
    .pop_up > :nth-child(2) {
        width: 450px;
    }
}

.err_msg {
    color: #ff6969;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 100%;
    text-align: left;
}
</style>
