<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <div class="fixed top-0 left-0 w-[100%] h-[100%] bg-[#00000052] z-30 flex justify-center items-center" v-if="showEmail">
        <div
            class="w-[75%] h-[fit-content] max-h-[95%] bg-[#fff] relative p-5 rounded-lg"
            style="
                box-shadow:
                    0 11px 15px -7px #0003,
                    0 24px 38px 3px #00000024,
                    0 9px 46px 8px #0000001f;
            "
        >
            <!-- Tabs Navigation -->
            <div class="flex border-b border-gray-300 mb-5">
                <button
                    :class="{ 'border-b-4 border-blue-600 text-blue-600': activeTab === 'candidates' }"
                    class="py-3 px-5 font-medium flex items-center gap-2 transition duration-300"
                    @click="activeTab = 'candidates'"
                >
                    <font-awesome-icon :icon="['fas', 'user-plus']" /> {{ $t("Invite Candidates") }}
                </button>

                <button
                    :class="{ 'border-b-4 border-blue-600 text-blue-600': activeTab === 'links' }"
                    class="py-3 px-5 font-medium flex items-center gap-2 transition duration-300"
                    @click="activeTab = 'links'"
                >
                    <font-awesome-icon :icon="['fas', 'link']" /> {{ $t("Invite Links") }}
                </button>
            </div>

            <!-- Tab Contents -->
            <div v-if="activeTab === 'candidates'" class="mt-5">
                <!-- Invite Candidates Content -->
                <div class="flex relative flex-col items-start justify-center gap-[0.5px]">
                    <div class="w-full flex justify-between mb-5">
                        <div class="flex flex-col">
                            <span class="text-[25px] font-bold text-[#1b2559]">{{ $t("Invite Candidates") }}</span>
                            <span class="text-[14px] text-gray-600">{{ $t("Invite candidates to pass assessments by email") }}.</span>
                        </div>
                        <div class="flex items-center gap-3">
                            <label for="file-upload" class="custom-file-upload shadow bg-blue-600 text-white px-3 py-1 rounded-lg cursor-pointer hover:bg-blue-700 transition">
                                <font-awesome-icon :icon="['fas', 'upload']" /> {{ $t("Upload File") }}
                            </label>
                            <input id="file-upload" accept=".xlsx, .csv" type="file" @change="handleFileUpload" class="hidden" />
                            <font-awesome-icon
                                @mouseover="showInfo = true"
                                @mouseleave="showInfo = false"
                                class="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer"
                                :icon="['fas', 'info-circle']"
                            />
                        </div>
                    </div>

                    <!-- File Info Tooltip -->
                    <div v-if="showInfo" class="absolute p-3 bg-white right-0 top-[20%] rounded shadow-md z-50">
                        <p class="text-sm text-gray-600">The file must be either <strong>xlsx</strong> or <strong>csv</strong> format:</p>
                        <ul class="list-disc pl-5 text-sm text-gray-600">
                            <li>First column: <strong>First Name</strong></li>
                            <li>Second column: <strong>Last Name</strong></li>
                            <li>Third column: <strong>Email</strong></li>
                        </ul>
                    </div>

                    <!-- Candidates Table -->
                    <div class="w-full mt-5 h-fit max-h-[450px] rounded-lg overflow-x-auto overflow-y-auto">
                        <table class="w-full text-left border-separate border-spacing-y-2">
                            <thead class="bg-gray-100 text-gray-700 text-sm">
                                <tr>
                                    <th class="px-2 py-2">{{ $t("First Name") }}</th>
                                    <th class="px-2 py-2">{{ $t("Last Name") }}</th>
                                    <th class="px-2 py-2">{{ $t("Email") }}</th>
                                    <th class="px-2 py-2 text-center">{{ $t("Actions") }}</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr v-for="(candidate, index) in candidates" :key="index" class="border-[#dcdcdc] text-[#2196f3] border-b bg-[#fff] border-neutral-200">
                                    <td
                                        class="whitespace-nowrap cursor-text transition duration-300 ease-in-out hover:bg-[#2371b631] px-2 py-4 font-medium"
                                        @click="editCandidate(candidate, 'first_name')"
                                    >
                                        <template v-if="!candidate.editing || candidate.editingField !== 'first_name'">{{ candidate.first_name }}</template>
                                        <input
                                            v-else
                                            class="border-[1px] focus:outline-none border-[#00000052] p-2 rounded-[8px]"
                                            type="text"
                                            v-model="candidate.first_name"
                                            @blur="saveCandidate(candidate)"
                                        />
                                    </td>
                                    <td class="whitespace-nowrap cursor-text transition duration-300 ease-in-out hover:bg-[#2371b631] px-2 py-4" @click="editCandidate(candidate, 'last_name')">
                                        <template v-if="!candidate.editing || candidate.editingField !== 'last_name'">{{ candidate.last_name }}</template>
                                        <input
                                            v-else
                                            class="border-[1px] focus:outline-none border-[#00000052] p-2 rounded-[8px]"
                                            type="text"
                                            v-model="candidate.last_name"
                                            @blur="saveCandidate(candidate)"
                                        />
                                    </td>
                                    <td class="whitespace-nowrap cursor-text transition duration-300 ease-in-out hover:bg-[#2371b631] px-2 py-4" @click="editCandidate(candidate, 'email')">
                                        <template v-if="!candidate.editing || candidate.editingField !== 'email'">{{ candidate.email }}</template>
                                        <input
                                            v-else
                                            class="border-[1px] focus:outline-none border-[#00000052] p-2 rounded-[8px]"
                                            type="email"
                                            v-model="candidate.email"
                                            @blur="saveCandidate(candidate)"
                                        />
                                    </td>
                                    <td
                                        class="whitespace-nowrap text-[#DC3E42] transition duration-300 ease-in-out hover:bg-[#FFDBDC] px-2 py-5 cursor-pointer flex justify-center items-center"
                                        @click="deleteCandidate(candidate)"
                                    >
                                        <font-awesome-icon class="" :icon="['fas', 'xmark']" />
                                    </td>
                                </tr>
                                <tr class="">
                                    <td class="px-2 py-4 font-medium">
                                        <input
                                            class="border-[1px] border-[#00000052] p-2 rounded-[8px] focus:outline-none"
                                            type="text"
                                            placeholder="First Name"
                                            @input="checkCandidate()"
                                            v-model="candidate.first_name"
                                        />
                                    </td>
                                    <td class="px-2 py-4">
                                        <input
                                            type="text"
                                            @input="checkCandidate()"
                                            placeholder="Last Name"
                                            class="border-[1px] border-[#00000052] p-2 rounded-[8px] focus:outline-none"
                                            v-model="candidate.last_name"
                                        />
                                    </td>
                                    <td class="px-2 py-4 relative">
                                        <input
                                            type="email"
                                            @input="checkCandidate()"
                                            class="border-[1px] border-[#00000052] p-2 rounded-[8px] focus:outline-none"
                                            placeholder="Email"
                                            v-model="candidate.email"
                                        />
                                        <span class="text-red-500 text-xs absolute bottom-0 left-7">{{ emailError }}</span>
                                    </td>
                                    <td class="px-2 py-4">
                                        <button
                                            @click="addCandidate"
                                            class="w-8 h-8 transition duration-300 ease-in-out hover:scale-110 text-xl shadow-md text-white bg-[#2196f3] flex rounded-[50%] justify-center items-center p-3"
                                            :class="{ '  ': isValid, 'opacity-[0.8]  cursor-not-allowed': !isValid }"
                                        >
                                            +
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="mostInvitedCandidates[0]" class="flex ml-1 flex-col items-start">
                        <span class="text-[16px] mb-2 font-bold text-[#1b2559]">{{ $t("Most invited candidates") }} :</span>
                        <div class="w-full flex flex-col gap-0.5" v-for="(candidate, index) in mostInvitedCandidates" :key="index">
                            <div class="border-[#dcdcdc] flex items-center justify-between gap-1 text-[0.9rem] text-[#1b2559] border-neutral-200">
                                <span class="whitespace-nowrap w-1/4 px-1 py-3">{{ candidate.first_name }} </span>
                                <span class="whitespace-nowrap w-1/4 px-1 py-3">{{ candidate.last_name }} </span>
                                <span class="whitespace-nowrap w-1/3 px-1 py-3">{{ candidate.email }}</span>
                                <button
                                    @click="addMostInvitedCandidate(candidate)"
                                    class="w-1/4 transition duration-100 ease-in-out hover:underline hover:underline-offset-4 text-small text-[#2196f3] flex rounded justify-center items-center pl-40 py-4 px-9"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full h-[80px] flex items-center" :class="{ 'justify-end': candidates.length === 0, 'justify-between': this.candidates.length > 0 }">
                    <div class="w-full flex justify-between items-center">
                        <button v-if="this.candidates.length > 0" class="w-[20%] h-[40px] rounded-[8px] font-medium shadow-xl bg-[#E5484D] hover:opacity-80 text-white" @click="clearList">
                            {{ $t("Clear List") }}
                        </button>
                        <div v-else class="w-[20%]"></div>

                        <div class="w-[40%] flex gap-2 justify-end items-center">
                            <ButtonComponent :action="closeInvitation" intent="secondary">
                                {{ $t("Cancel") }}
                            </ButtonComponent>
                            <ButtonComponent v-if="!isLoading" :action="sendInvitationEmail" intent="primary">
                                {{
                                    this.candidates.length < 2
                                        ? $t("Send invitation")
                                        : `Invite ${this.candidates.length}
                                    Candidates`
                                }}
                            </ButtonComponent>
                            <ButtonComponent v-else loading intent="primary">
                                {{
                                    this.candidates.length < 2
                                        ? $t("Send invitation")
                                        : `Invite
                                            ${this.candidates.length} Candidates`
                                }}
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeTab === 'links'" class="mt-5">
                <!-- Invite Links Content -->
                <div class="flex flex-col items-start justify-center gap-[0.5px]">
                    <span class="text-[25px] font-bold text-[#1b2559]">{{ $t("Invite Links") }}</span>
                    <span class="text-[14px] text-gray-600">{{ $t("Invite candidates via link through social media network or your own careers page") }}.</span>
                </div>

                <div class="mt-5">
                    <table class="w-full text-left border-separate border-spacing-y-2">
                        <thead class="bg-gray-100 text-gray-700 text-sm">
                            <tr>
                                <th class="px-4 py-2">{{ $t("Source") }}</th>
                                <th class="px-4 py-2"></th>
                                <th class="px-4 py-2 text-center">{{ $t("Actions") }}</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white text-gray-700">
                            <tr>
                                <td class="px-4 py-2">
                                    <div class="link-container">
                                        <a @click.prevent="handleClick" href="#" class="text-blue-600 hover:underline">
                                            {{ url }}
                                            <!--{{ $t("Click to copy your invite link") }}-->
                                        </a>
                                    </div>
                                </td>
                                <td></td>

                                <td class="px-4 py-2 text-center">
                                    <button type="button" class="copy bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition" data-copy=".link-container" @click="copyLink">
                                        <i class="fas fa-copy"></i>
                                        <span class="copy-label ml-2"> {{ $t("Copy Link") }}</span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="flex items-center gap-3 mt-5">
                    <span class="text-gray-600">{{ $t("Post to") }}:</span>
                    <button @click="shareOnLinkedin" class="bg-blue-700 text-white px-3 py-2 rounded-full hover:bg-blue-800 transition">
                        <i class="fab fa-linkedin-in"></i>
                    </button>
                </div>
                <div class="w-full flex justify-end mt-8 items-center">
                    <div class="w-[20%] flex gap-2 justify-end items-center">
                        <ButtonComponent :action="closeInvitation" intent="secondary">
                            {{ $t("Cancel") }}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ToastNotification from "@/components/ToastNotification";
import { useStore } from "@/store/index";
import * as XLSX from "xlsx";
import ButtonComponent from "@/components/ReusableComponents/ButtonComponent.vue";
export default {
    name: "InviteCandidate",
    props: ["showEmail", "toggleEmail", "projectId", "assessmentsLength"],
    components: { ToastNotification, ButtonComponent },
    data() {
        return {
            linkedinId: "YOUR_LINKEDIN_USER_ID", // Should be dynamically fetched
            title: "Your Title",
            text: "Your Description",
            url: "https://example.com",
            imageUrl: "https://example.com/image.jpg",

            isAuthorized: false,
            email: "",
            thumbUrl: "www.google.com",
            activeTab: "candidates",
            isVisible: false,
            showInfo: false,
            message: "",
            bgc: "",
            accessToken: null,
            isLoading: false,
            candidates: [],
            mostInvitedCandidates: [],
            candidate: {
                first_name: "",
                last_name: "",
                email: "",
            },
            emailError: "",
            isValid: false,
            articleUrl: "",
            articleTitle: "",
            articleDescription: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },

    watch: {
        projectId: {
            async handler(newVal) {
                if (this.projectId) {
                    let config = {
                        method: "get",
                        maxBodyLength: Infinity,
                        url: "https://server.go-platform.com/inviteCandidate/multi-invite-link/" + newVal,
                        withCredentials: true,
                    };
                    axios
                        .request(config)
                        .then((response) => {
                            this.url = JSON.parse(JSON.stringify(response.data)).generatedLink;
                            //this.url=JSON.stringify(response.data["generatedLink"]);
                            document.getElementById("dynamicLink").href = this.url;
                            //document.getElementById("linkedinShareBtn").addEventListener("click", this.postToLinkedIn(this.url));
                            this.postToLinkedIn();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            },
            immediate: true, // Trigger the watcher immediately on component creation
        },
    },

    methods: {
        clearList() {
            this.candidates = [];
        },
        copyLink() {
            document.querySelectorAll(".copy").forEach((copyButton) => {
                // Function to handle the copy action
                const handleCopyEvent = () => {
                    //const targetElement = document.querySelector(copyButton.dataset.copy);
                    //const textToCopy = targetElement.textContent;
                    navigator.clipboard.writeText(this.url).then(() => {
                        const label = copyButton.querySelector(".copy-label");
                        const originalText = label.textContent;
                        copyButton.disabled = true;
                        label.textContent = "Copied!";

                        setTimeout(() => {
                            copyButton.disabled = false;
                            label.textContent = originalText;
                        }, 10000);
                    });
                };

                // Add event listeners for different types of clicking events
                const clickEvents = ["click", "mousedown", "mouseup", "dblclick", "contextmenu"];
                clickEvents.forEach((eventType) => {
                    copyButton.addEventListener(eventType, handleCopyEvent);
                });
            });
        },

        closeInvitation() {
            this.toggleEmail();
            this.clearList();
        },
        sendInvitationEmail() {
            if (this.assessmentsLength === 0) {
                this.message = "Please add an assessment to invite candidates";
                this.bgc = "red";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
            if (this.candidates.length > 0 && this.projectId) {
                if (this.candidates.length > this.Store.companyCredit && this.Store.companyPlan == "pay-per-use") {
                    this.message = "You do not have enough credit to invite this number of candidates";
                    this.bgc = "red";
                    this.isVisible = true;
                    setTimeout(() => {
                        this.isVisible = false;
                    }, 5000);
                } else {
                    this.isLoading = true;
                    let data = JSON.stringify({
                        candidates: this.candidates,
                        projectId: this.projectId,
                        company_name: this.Store.company_name,
                    });

                    let config = {
                        method: "post",
                        maxBodyLength: Infinity,
                        url: "https://server.go-platform.com/inviteCandidate",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                        data: data,
                    };

                    axios
                        .request(config)
                        .then((response) => {
                            this.toggleEmail();
                            this.message = response.data.message;
                            this.isLoading = false;
                            this.bgc = "success";
                            this.candidates = [];
                            this.email = "";
                            this.isVisible = true;
                            this.Store.getCompanyCredit();
                            setTimeout(() => {
                                this.isVisible = false;
                            }, 5000);
                        })
                        .catch((error) => {
                            console.log({ error });
                            this.isLoading = false;
                            this.message = "Please enter a valid email address";
                            this.bgc = "red";
                            this.email = "";
                            this.isVisible = true;
                            setTimeout(() => {
                                this.isVisible = false;
                            }, 5000);
                        });
                }
            } else {
                alert("Please add at least one candidate to invite");
            }
        },
        editCandidate(candidate, field) {
            candidate.editing = true;
            candidate.editingField = field;
        },
        saveCandidate(candidate) {
            // Count the occurrences of the email address in the candidates array
            const emailOccurrences = this.candidates.filter((candid) => candidate.email === candid.email).length;

            if (emailOccurrences < 2) {
                // If there are less than two candidates with the same email, proceed with saving the candidate
                candidate.editing = false;
                candidate.editingField = null;
            } else {
                alert("Email already added for two candidates");
            }
        },
        deleteCandidate(candidate) {
            const index = this.candidates.indexOf(candidate);
            if (index !== -1) {
                this.candidates.splice(index, 1);
            } else {
                console.error("Candidate not found");
            }
        },

        checkCandidate() {
            let isValid = true;
            this.emailError = "";
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

            if (!emailRegex.test(this.candidate.email) || this.candidate.email.includes(",")) {
                isValid = false; // Update the formValid flag
                this.emailError = "Please enter a valid email";
            }
            if (!this.candidate.first_name || !this.candidate.last_name) {
                isValid = false;
            }
            if (!this.candidate.first_name && !this.candidate.last_name && !this.candidate.email) {
                this.emailError = "";
            }
            this.isValid = isValid;
            return isValid;
        },
        addCandidate() {
            const duplicatedCandid = this.candidates.find((candid) => this.candidate.email === candid.email);
            if (this.checkCandidate() && !duplicatedCandid) {
                this.candidates.push(this.candidate);
                this.candidate = {
                    first_name: "",
                    last_name: "",
                    email: "",
                };
                this.isValid = false;
            }
        },
        addMostInvitedCandidate(candidate) {
            const duplicatedCandid = this.candidates.find((candid) => candidate.email === candid.email);
            if (!duplicatedCandid) {
                this.candidates.push(candidate);
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // Handle CSV files
                if (file.type === "text/csv") {
                    const csvData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                        header: 1,
                    });
                    const parsedCandidates = csvData.slice(1).map((row) => ({
                        first_name: row[0] || "",
                        last_name: row[1] || "",
                        email: row[2] || "",
                    }));
                    this.candidates.push(...parsedCandidates);
                } else {
                    // Handle Excel files
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                    });
                    const parsedCandidates = jsonData.slice(1).map((row) => ({
                        first_name: row[0] || "",
                        last_name: row[1] || "",
                        email: row[2] || "",
                    }));
                    this.candidates.push(...parsedCandidates);
                }
            };

            reader.readAsArrayBuffer(file);
        },

        shareOnLinkedin() {
            const url = "https://server.go-platform.com/shareJobOnLinkedin/authentication";
            window.open(url, "_blank", "width=700,height=700");
        },
    },
};
</script>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
<style lang="scss" scoped>
input::placeholder {
    font-weight: 300;
    /* You can adjust the value to a lighter weight as needed */
}

input {
    padding: 0.7rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #d8e2ee;
    font-weight: 200;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #2196f3;
    color: white;
    border-radius: 20px;
    font-weight: 500;
    text-align: center;
}

.custom-file-upload:hover {
    opacity: 0.85;
}

.assessement-preview {
    position: fixed;
    z-index: 100;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #0000003f;
    align-items: center;

    .wrapper {
        background: #f4f7fe;
        padding: 20px;
        border-radius: 50px;
        width: 750px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .preview-close {
        position: fixed;
        z-index: -1;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    > :nth-child(4) {
        border-radius: 10px;
        padding: 2rem 3rem;
        width: 50%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        background: #fff;
        align-self: center;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);
        gap: 1rem;
        color: #050708;

        .preview-header {
            position: relative;
            //grid-area: head;
            font-size: 38px;
            font-weight: 600;
            line-height: 22px;
            color: #2196f3;
            padding-bottom: 1rem;
            text-transform: uppercase;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background: #c3cad9;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .preview-description {
            display: flex;
            flex-direction: column;
            //grid-area: description;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            > span {
                font-weight: 400;
                padding: 1rem;
            }
        }

        .preview-cards {
            //grid-area: cards;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            gap: 1rem;
            /*border: 1px solid #C3CAD9;
            border-radius: 20px;
            box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);*/
            padding: 1rem 0;

            > * {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: center;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 2rem;

                    font-weight: 700;
                    font-size: 30px;
                    line-height: 22px;
                    text-align: center;
                    padding: 0.5rem;
                    color: #2196f3;
                }
            }
        }
    }
}

.icon {
    font-size: 6rem;
    color: #2196f3;
}

.preview-btn {
    //grid-area: btns;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 100%;
        padding: 0.5rem;
        border-radius: 5px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        text-align: center;
        border-radius: 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            font-size: 20px;
        }
    }

    > :first-child {
        background: #fff;
        box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
        color: #050708;
        border: 1px solid #c3cad9;
    }
}

.title-section {
    display: flex;
    align-items: center;
    margin-top: 48px;
}

h1 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    color: #656669;
    margin-right: 56px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
    height: 8vh;
    width: 60%;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #adb8cc;
}

.cards {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.assessments {
    padding: 24px 8px 24px 24px;
    display: grid;
    grid-template-columns: 27% 27% 27% auto;
    align-items: flex-start;
    gap: 16px;
}

.validate-button {
    background: #2196f3;
    border: none;
    border-radius: 70px;
    padding: 12px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    width: 100%;
    height: fit-content;
    margin-bottom: 5px;
}

.success-score {
    display: grid;
    grid-template-columns: 6fr 1fr;
    padding-left: 48px;
}

.slider {
    display: flex;
    align-items: center;
}

.success-score-input {
    background: #fff;
    border: 1px solid #e5e5ef;
    border-radius: 12px;
    padding: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(90deg, #2196f3 60%, #fff 60%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 35vw;
    height: 16px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}

#success-score {
    border: none;
    margin: 0;
    font-family: "Roboto";
    font-weight: 500;
    width: 56px;
    font-size: 24px;
    text-align: center;
    color: #000000;
}

.grid-row-1 {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 75px;
    padding: 24px;
}

.grid-row-1 > div > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.test-compisition {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
}

.top-section {
    display: flex;
    justify-content: space-between;
}

.top-section > .text {
    display: flex;
    flex-direction: column;
}

.top-section > .text > * {
    margin-bottom: 24px;
}

.top-section > .text > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
}

.chart {
    margin: 16px 8px;
}

.recent-candidats {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 20px;
}

.trash-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-icon {
        color: red;
    }
}

.trash-btn:hover {
    border: 2px solid red;
}

//////////////////////////////////
///
///

.social-media {
    // opacity: 0;
    //    transition: .5s;
    //  transition-delay: 0s;
    //width: 250px;
    //height: 120px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
}

.social-media-btn {
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    //background: #000;
    color: #fff;
    line-height: 30px;
    font-size: 15px;
    cursor: pointer;
    margin: 0 5px;
    text-align: center;
}

.social-media-btn:nth-child(4) {
    background: #1da1f2;
}

.social-media-btn:nth-child(3) {
    background: black;
}

.social-media-btn:nth-child(2) {
    background: #1877f2;
}

.social-media-btn:nth-child(6) {
    background: #0a66c2;
}

.link-container {
    //opacity: 0;
    //transition: .5s;
    //transition-delay: 0s;
    width: 100%;
    //position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    //  background-color: #fff;
    overflow: hidden;
    padding: 0 10px;
}

.link {
    width: 80%;
    height: 100%;
    line-height: 40px;
    color: #000;
}

.copy-btn {
    //position: absolute;
    //    right: 0;
    cursor: pointer;
    //  background: #000;
    //    color: #fff;
    border: none;
    height: 100%;
    width: 30%;
    text-transform: capitalize;
    font-size: 16px;
}

.copy:active {
    background-color: blue-500;
}

.copy:disabled {
    background: #f1f1f1;
    color: #333333;
    cursor: not-allowed;
}
</style>
