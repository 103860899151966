<template>
    <div class="wrapper" v-if="showPreviewWindow">
        <div class="exit_zone" v-if="showPreviewWindow" @click="toggleshowPreviewWindow"></div>
        <div class="previewProject">
            <div class="w-full pl-1 pr-1 flex justify-between">
                <h1 class="projectName">{{ project.name }}</h1>
                <div class="flex flex-row relative btnWrapper">
                    <button class="nav-btn moreBtn" @click="toggleMenu">
                        <font-awesome-icon :icon="['fas', 'ellipsis']" class="nav-icon" />
                    </button>
                    <button class="nav-btn trash-btn" @click="toggleshowPreviewWindow">
                        <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                        <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                    </button>
                    <ProjectMenu :showDetails="showMenu" :toggleMenu="toggleMenu" :thisProject="project" />
                </div>
            </div>
            <div class="jobTitle">
                <!-- <h1>
                    <span>{{ $t("Job title") }}:</span> {{ project.jobTitle }}
                </h1>
                <h1>
                    <span>{{ $t("Seniority") }}:</span> {{ project.seniority }}
                </h1>
                <h1>
                    <span>{{ $t("Minimum Score") }}:</span>
                    {{ project.min_score != 0 ? project.min_score : project.recommanded_score }}%
                </h1> -->

                <div>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" :title="$t('Role')">
                        <!-- {{ $t("Role") }}: -->
                        <font-awesome-icon :icon="['fas', 'briefcase']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span>{{ $t("Job title") }}:</span>
                        <span class="pl-2 font-light capitalize" style="font-size: 20px"> {{ project.jobTitle }}</span>
                    </span>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" title="Experience">
                        <!-- {{ $t("Experience") }}: -->
                        <font-awesome-icon :icon="['fas', 'ranking-star']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span>{{ $t("Seniority") }}:</span>
                        <span class="font-light pl-2" style="font-size: 20px">{{ project.seniority }}</span>
                    </span>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" title="Score">
                        <!-- {{ $t("Experience") }}: -->
                        <font-awesome-icon :icon="['fas', 'percent']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span>{{ $t("Success Score") }}:</span>
                        <span class="font-light pl-2" style="font-size: 20px">{{ project.min_score != 0 ? project.min_score : project.recommanded_score }}%</span>
                    </span>
                </div>
                <div>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" title="Date">
                        <!-- {{ $t("Experience") }}: -->
                        <font-awesome-icon :icon="['fas', 'calendar']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span class="w-[30%]">{{ $t("Created") }}:</span>
                        <span class="font-light pl-2 w-[70%]" style="font-size: 20px">{{ getDate(project.createdAt) }}</span>
                    </span>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" v-if="project.project_status === 'Active'" :title="$t('Status')">
                        <!-- {{ $t("Experience") }}: -->
                        <font-awesome-icon :icon="['fas', 'bolt']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span>{{ $t("Status") }}:</span>
                        <span class="font-light pl-2">{{ $t("Active") }}</span>
                    </span>
                    <span class="w-full text-lg font-medium text-start mb-3 flex flex-row justify-start gap-2 items-center" v-else :title="$t('status')">
                        <!-- {{ $t("Experience") }}: -->
                        <font-awesome-icon :icon="['fas', 'box-archive']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                        <span>{{ $t("Status") }}:</span>
                        <span class="font-light pl-2" style="font-size: 20px">{{ $t("Archived") }}</span>
                    </span>
                    <!-- <span class="w-full text-lg font-medium text-start flex flex-row justify-start gap-2 items-center">
                    <font-awesome-icon :icon="['fas', 'cubes']" class="text-[#2196f3] text-xl w-[10%] text-left" />
                    <span class="font-light pl-2">{{ project.assessments.length + " " + $t("Assessments") }} </span>
                    </span> -->
                </div>
            </div>
            <div class="assessments-section">
                <h1 class="title">{{ $t("Assessments") }}:</h1>
                <div class="assessments">
                    <LibraryCard v-for="(assess, index) in project.assessments" :key="index" :assessement="assess" @click="OpenPreview(assess)" @delete="deleteAssessement(assess)" />
                </div>
            </div>
            <div class="preview-btn">
                <button
                    @click="
                        this.$router.push({
                            path: '/boards',
                            query: { id: project._id },
                        })
                    "
                >
                    {{ $t("Boards") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LibraryCard from "@/components/LibraryCard.vue";
import ProjectMenu from "./ProjectMenu.vue";
export default {
    name: "ProjectPreview",
    components: { LibraryCard, ProjectMenu },

    props: {
        toggleshowPreviewWindow: {
            type: Function,
        },
        showPreviewWindow: {
            type: Boolean,
        },
        project: {
            type: Object,
        },
    },
    data() {
        return {
            jobTitle: "",
            jobs: [],
            showMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        getDate(str) {
            // const parts = str.split("-");
            const date = new Date(str);
            const extractedDate = date.toISOString().split("T")[0];
            // console.log("the date ", extractedDate);
            return extractedDate;
        },
    },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
    background-color: #2196f334;
    border-radius: 500px;
    margin-right: 12px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
    border-radius: 40px; /* Set the border-radius to 40px for rounded corners */
    background-color: #2196f3; /* Set the background color of the scrollbar thumb */
}
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0000002f;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    > .previewProject {
        width: 60%;
        height: 85%;
        border-radius: 20px;
        background-color: #edeff2;
        background-color: #ffffff;
        padding: 3%;
        // overflow: hidden;
        animation: slideFromBottom 0.5s ease forwards;
    }
}
.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
@keyframes slideFromBottom {
    0% {
        transform: translateY(5%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.assessments-section {
    height: 40%;
    .title {
        width: 100%;
        padding: 2%;
        padding-top: 0;
        // margin-top: 3%;
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        color: #2b3679;
    }

    .assessments {
        width: 100%;
        height: 70%;
        padding: 24px 30px 24px 24px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        align-items: flex-start;
        gap: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow: 8px 5px 14px -8px rgba(43, 54, 121, 0.42);
        -webkit-box-shadow: 8px 5px 14px -8px rgba(43, 54, 121, 0.42);
        -moz-box-shadow: 8px 5px 14px -8px rgba(43, 54, 121, 0.42);
    }
    overflow: hidden;
}

.preview-btn {
    //grid-area: btns;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    justify-content: center;
    gap: 1rem;
    align-items: flex-end;
    margin-top: 3%;

    > * {
        width: 20%;
        height: 100%;
        padding: 0.25rem;
        border-radius: 20px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
            transform: scale(1.05);
        }
    }

    // > :first-child {
    //     background: #fff;
    //     box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
    //     color: #050708;
    //     border: 1px solid #c3cad9;
    // }
}

.jobTitle {
    // border: 2px solid red;
    width: 76%;
    margin: 0 12%;
    margin-bottom: 5%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > :nth-child(2) {
        option {
            background-color: red;
        }
    }
}

.projectName {
    width: 100%;
    margin: 0;
    // text-align: center;
    font-size: 32px;
    font-weight: 500;
    color: #2b3679;
    width: 100%;
    // text-align: center;
}

.jobTitle {
    padding: 2%;
    margin-top: 3%;
    padding-bottom: 0;
    font-size: 24px;
    font-weight: 500;

    span {
        color: #2b3679;
        font-size: 20px;
    }
}

.trash-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    .nav-icon {
        color: red;
        font-size: 25px;
    }
}
.trash-btn:hover {
    // border: 2px solid red;
}

.trash-icon {
    color: red;
}

.trash-btn:hover {
    border: 2px solid red;
}

.trash-btn:hover + .trash-icon {
    color: red;
}
.nav-btn {
    // padding-top: 2px;
}

.btnWrapper {
    > :first-child {
        margin-right: 1rem;

        .nav-icon {
            color: #2196f3;

            // &:hover {
            //     border: 2px solid #2196f3;
            // }
        }
    }
}
.moreBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    .nav-icon {
        font-size: 25px;
    }
    color: #2196f3;
    &:hover {
        border: 2px solid #2196f3;
    }
}
</style>
